<template>
  <div v-if="airdropItem.participateDesc" class="info-text">
    <span class="text-title">How to participate</span>
    <p class="text-content">
      <BRRichTextRenderer :document="airdropItem.participateDesc" />
    </p>
  </div>
</template>

<script>
import BRRichTextRenderer from '@/components/BRComponents/BRRichTextRenderer.vue'

export default {
  name: 'AirdropItemPageInfo',
  components: { BRRichTextRenderer },
  props: {
    airdropItem: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.info-text {
  border-bottom: var(--default-border);
  padding-bottom: 30px;

  .text-title {
    display: block;
    margin-bottom: 18px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h3);

    @include breakpoint-reverse(small) {
      padding-top: 2px;
    }
  }

  .text-content {
    color: var(--secondary-text-color);
  }

  @include breakpoint-reverse(small) {
    padding-bottom: 65px;
  }
}
</style>
