<template>
  <div class="airdrop-item-overview">
    <div class="overview-status">
      <span class="status-heading">Status</span>
      <div class="status-title-wrapper">
        <span class="status-title">
          AirDrop
        </span>
        <BRPeriodChip
          class="status-chip"
          :date-end="airdropItem.dateEnd"
          :date-start="airdropItem.dateStart"
        />
      </div>
    </div>
    <div class="overview-table">
      <div class="table-left">
        <div v-if="airdropItem.estValue" class="table-item">
          <span class="table-info-title">Est. Value:</span>
          <span class="table-info-value">
            {{ airdropItem.estValue }}
          </span>
        </div>
        <div v-if="airdropItem.tokensToClaim" class="table-item">
          <span class="table-info-title">Tokens to claim:</span>
          <span class="table-info-value">
            {{ airdropItem.tokensToClaim }}
          </span>
        </div>
        <div class="table-item">
          <span class="table-info-title">Max participants:</span>
          <span class="table-info-value">
            <!--      if maxParticipants === 0 then Unlimited      -->
            {{ airdropItem.maxParticipants || 'unlimited' }}
          </span>
        </div>
      </div>
      <div class="table-right">
        <div v-if="airdropItem.chains" class="table-item">
          <span class="table-info-title">Chains:</span>
          <span class="table-info-value">
            {{ airdropItem.chains }}
          </span>
        </div>
        <div v-if="airdropItem.holdingRequirement" class="table-item">
          <span class="table-info-title">Holding requirement:</span>
          <span class="table-info-value">
            {{ airdropItem.holdingRequirement }}
          </span>
        </div>
        <div v-if="airdropItem.socialRequirement" class="table-item">
          <span class="table-info-title">Social requirement:</span>
          <span class="table-info-value">
            <BRIcon
              v-for="(item, index) in airdropItem.socialRequirement"
              :key="index"
              class="social-icon"
              :icon="socialIcons[item]"
              fill-color="var(--secondary-text-color)"
              width="20"
              height="20"
            />
          </span>
        </div>
        <div class="table-item">
          <span class="table-info-title">End date:</span>
          <span class="table-info-value">
            {{ formatDate(airdropItem.dateEnd) }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="airdropItem.aboutToken" class="overview-text">
      <span class="text-title">About Token</span>
      <p class="text-content">
        <BRRichTextRenderer :document="airdropItem.aboutToken" />
      </p>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/services/date'
import BRIcon from '@/components/BRComponents/BRIcon.vue'
import BRRichTextRenderer from '@/components/BRComponents/BRRichTextRenderer.vue'
import BRPeriodChip from '@/components/BRComponents/BRPeriodChip.vue'

export default {
  name: 'AirdropItemPageOverview',
  components: {
    BRIcon,
    BRPeriodChip,
    BRRichTextRenderer
  },
  props: {
    airdropItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      socialIcons: {
        Discord: 'discord',
        Instagram: 'socials-instagram',
        Facebook: 'socials-facebook',
        Medium: 'medium',
        Reddit: 'reddit',
        Telegram: 'telegram',
        Twitter: 'socials-twitter',
        YouTube: 'youtube'
      }
    }
  },
  methods: {
    formatDate
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.airdrop-item-overview {
  padding-right: 23px;

  @include breakpoint-reverse(medium) {
    padding-right: 0;
  }
}

.overview-status {
  margin-bottom: 14px;

  .status-heading {
    display: block;
    margin-bottom: 22px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h3);

    @include breakpoint-reverse(small) {
      display: none;
    }
  }

  .status-title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    @include breakpoint-reverse(small) {
      justify-content: space-between;
      margin-bottom: 21px;
    }
  }

  .status-title {
    margin-right: 19px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h3);
  }

  .status-chip {
    margin-top: 3px;

    @include breakpoint-reverse(small) {
      margin-top: 0;
    }
  }

  .status-date-title {
    display: none;
    margin-bottom: 7px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    color: var(--text-color);

    @include breakpoint-reverse(small) {
      display: block;
    }
  }

  .status-date {
    display: flex;
    font-size: var(--font-sm);
    color: var(--secondary-text-color);
  }

  @include breakpoint-reverse(small) {
    margin-bottom: 21px;
  }
}

.overview-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: var(--default-border);
  border-bottom: var(--default-border);

  .table-left,
  .table-right {
    padding: 16px;

    @include breakpoint-reverse(small) {
      padding: 0;
    }
  }

  .table-left {
    border-right: var(--default-border);
    padding-left: 0;

    @include breakpoint-reverse(small) {
      border-right: none;
      border-bottom: var(--default-border);
      padding-bottom: 20px;
    }
  }

  .table-right {
    padding-right: 0;

    @include breakpoint-reverse(small) {
      padding-top: 20px;
    }
  }

  .table-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
    gap: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .table-info-title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    white-space: nowrap;
  }

  .table-info-value {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-end;
    font-size: var(--font-base);
    word-break: break-word;
    text-align: right;
    color: var(--secondary-text-color);
    gap: 4px;
  }

  .table-info-link {
    word-break: break-word;
    text-align: right;
  }

  .table-info-link > a {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    color: var(--link-color);
  }

  @include breakpoint-reverse(small) {
    grid-template-columns: 1fr;
    padding: 20px 0;
  }
}

.overview-text {
  padding: 20px 0;

  .text-title {
    display: block;
    margin-bottom: 21px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h3);

    @include breakpoint-reverse(small) {
      margin-bottom: 14px;
    }
  }

  .text-content {
    color: var(--secondary-text-color);
  }

  @include breakpoint-reverse(small) {
    margin-bottom: 65px;
    padding: 22px 0 0;
  }
}
</style>
