<template>
  <div class="airdrop-item-header">
    <div class="header-main-content">
      <div class="header-image-wrapper">
        <img
          :src="getContentfulImage(airdrop.logo, 'airdrop')"
          alt="airdrop avatar"
          class="header-image"
          height="60"
          width="60"
        >
      </div>
      <div class="header-content-wrapper">
        <div class="header-chips">
          <BRPeriodChip :date-end="airdrop.dateEnd" :date-start="airdrop.dateStart" />
        </div>
        <div class="title-block">
          <span class="header-title">
            {{ airdrop.title }}
          </span>
          <span v-if="airdrop.ticker" class="header-label">
            {{ airdrop.ticker }}
          </span>
        </div>
      </div>
      <AirdropLike
        :id="airdrop.id"
        :liked="airdrop.isLiked"
        :likes="airdrop.likesAmount"
        class="header-likes"
        @toggleLike="$emit('toggleLike', $event)"
      />
    </div>
    <div class="header-date">
      <span class="date-title">Ongoing date:</span>
      <div class="date-text">
        {{ getTimeLeft ? `Ends in ${getTimeLeft}` : 'No time left' }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate, timeLeft } from '@/services/date'
import images from '@/mixins/image'
import BRPeriodChip from '@/components/BRComponents/BRPeriodChip.vue'
import AirdropLike from '@/components/AirdropLike.vue'

export default {
  name: 'AirdropItemPageHeader',
  components: {
    AirdropLike,
    BRPeriodChip
  },
  mixins: [images],
  props: {
    airdropItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isShowDateStartDialog: false,
      isShowDateEndDialog: false,
      airdrop: this.airdropItem,
      dateStart: this.airdropItem.dateStart.substr(0, 10),
      dateEnd: this.airdropItem.dateEnd.substr(0, 10)
    }
  },
  computed: {
    getTimeLeft() {
      return this.timeLeft(new Date(), this.airdropItem.dateEnd)
    }
  },
  watch: {
    airdropItem() {
      this.airdrop = this.airdropItem
      this.dateEnd = this.airdropItem.dateEnd
      this.dateStart = this.airdropItem.dateStart
    }
  },
  methods: {
    timeLeft,
    formatDate
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.airdrop-item-header {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
  grid-template-columns: auto auto;
  padding-bottom: 35px;

  @include breakpoint-reverse(small) {
    grid-template-columns: 1fr;
    padding-bottom: 26px;
  }
}

.header-main-content {
  display: flex;
  align-items: center;
  gap: 20px;

  .header-image-wrapper {
    overflow: hidden;
    margin-right: 20px;
    border-radius: 30px;
    width: 60px;
    height: 60px;

    .header-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    @include breakpoint-reverse(small) {
      margin-right: 16px;
      border-radius: 25px;
      width: 50px;
      height: 50px;
    }
  }

  .header-chips {
    display: flex;
    margin-bottom: 7px;

    @include breakpoint-reverse(small) {
      display: none;
    }
  }

  .header-title {
    margin-bottom: 4px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h1);
  }

  .header-label {
    display: block;
    border-radius: var(--border-radius-small);
    padding: 4px 14px;
    width: fit-content;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-xs);
    text-transform: uppercase;
    color: var(--secondary-text-color);
    background-color: var(--tertiary-bg-color);

    @include breakpoint-reverse(small) {
      padding: 5px 10px;
    }
  }
}

.header-likes {
  display: none;
  grid-area: like;
  margin-right: 14px;
  margin-left: auto;
  gap: 7px;

  &::v-deep .like-icon {
    width: 22px;
    height: 22px;
  }

  @include breakpoint-reverse(small) {
    display: flex;
  }
}

.header-date {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: end;
  margin-bottom: 7px;
  gap: 12px;

  .date-title {
    display: block;
    margin-right: auto;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
  }

  .date-text {
    display: flex;
    font-size: var(--font-base);
    color: var(--secondary-text-color);
  }

  @include breakpoint-reverse(small) {
    display: none;
  }
}
</style>
