<template>
  <div class="airdrop-item-social">
    <div v-if="airdropItem.findText" class="social-text">
      <span class="text-title">Here you can find Airdrop</span>
      <p class="text-content">
        {{ airdropItem.findText }}
      </p>
    </div>
    <div v-if="airdropItem.socialLinks" class="social-table">
      <div class="table-left">
        <div
          v-for="(social, i) in airdropItem.socialLinks"
          :key="i"
          class="table-item"
        >
          <BRIcon class="social-icon" :icon="social.fields.type" fill-color="var(--text-color)" />
          <span class="table-info-title">{{ social.fields.type }}:</span>
        </div>
      </div>
      <div class="table-right">
        <div
          v-for="({ fields }, i) in airdropItem.socialLinks"
          :key="i"
          class="table-item"
        >
          <a :href="fields.link" class="table-info-link">{{ fields.link }}</a>
        </div>
      </div>
    </div>
    <div v-if="airdropItem.airdropReward || airdropItem.referralReward" class="social-rewards">
      <p v-if="airdropItem.airdropReward" class="social-rewards-item">
        <span class="social-rewards-item-title">AirDrop reward:</span>
        <span class="social-rewards-item-value">{{ airdropItem.airdropReward }}</span>
      </p>
      <p v-if="airdropItem.referralReward" class="social-rewards-item">
        <span class="social-rewards-item-title">Referral reward:</span>
        <span class="social-rewards-item-value">{{ airdropItem.referralReward }}</span>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AirdropItemPageSocial',
  props: {
    airdropItem: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.social-text {
  padding-bottom: 30px;

  .text-title {
    display: block;
    margin-bottom: 18px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h3);

    @include breakpoint-reverse(small) {
      padding-top: 2px;
    }
  }

  .text-content {
    color: var(--secondary-text-color);
  }
}

.social-table {
  display: flex;
  border-top: var(--default-border);
  border-bottom: var(--default-border);

  .table-left,
  .table-right {
    padding: 16px;
  }

  .table-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: var(--default-border);
    padding-left: 0;
    gap: 15px;

    @include breakpoint-reverse(small) {
      border-right: none;
    }
  }

  .table-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding-right: 0;
  }

  .table-item {
    display: flex;
    align-items: center;

    .social-icon {
      margin-right: 10px;
      border-radius: 50%;
      width: 18px;
      height: 18px;

      &_telegram::v-deep {
        circle {
          fill: var(--text-color);
        }

        path {
          fill: var(--content-bg-color);
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include breakpoint-reverse(small) {
      margin-bottom: 17px;
    }
  }

  .table-info-title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    text-transform: capitalize;
  }

  .table-info-link {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    line-height: 24px;
    color: var(--link-color);
  }

  @include breakpoint-reverse(medium) {
    margin-bottom: 20px;
  }

  @include breakpoint-reverse(small) {
    justify-content: space-between;
    margin-bottom: 0;
    border-bottom: none;
  }
}

.social-rewards {
  display: none;

  @include breakpoint-reverse(small) {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 23px 20px;
    background-color: var(--content-bg-color);
    gap: 15px;
  }
}

.social-rewards-item {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-base);
}

.social-rewards-item-title {
  font-weight: var(--font-weight-bold);
}

.social-rewards-item-value {
  color: var(--secondary-text-color);
}
</style>
