<template>
  <section class="airdrop-item">
    <template v-if="airdropItem">
      <AirdropItemPageHeader
        :airdrop-item="airdropItem"
        @toggleLike="handleLikesValue"
      />
      <div class="airdrop-item-content">
        <div class="airdrop-item-main">
          <BRTabs
            :tabs="tabs"
            class="airdrop-tabs"
            tab-width="180"
            @changeActiveTab="changeActiveTab"
          />
          <component
            :is="currentTab"
            :airdrop-item="airdropItem"
          />
          <BRButton class="join-button" dark height="40">
            JOIN AIRDROP
          </BRButton>
        </div>
        <AirdropAside
          :preview-item="airdropItem"
          class="airdrop-item-aside"
          @toggleLike="handleLikesValue"
        />
      </div>
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import api from '@/api'
import BRTabs from '@/components/BRComponents/BRTabs.vue'
import BRButton from '@/components/BRComponents/BRButton.vue'
import AirdropItemPageHeader from '@/components/AirdropItemPageHeader'
import AirdropAside from '@/components/AirdropAside'
import AirdropItemPageOverview from '@/components/AirdropItemPageOverview'
import AirdropItemPageInfo from '@/components/AirdropItemPageInfo'
import AirdropItemPageSocial from '@/components/AirdropItemPageSocial'

export default {
  name: 'AirdropItemPage',
  components: {
    BRTabs,
    BRButton,
    AirdropItemPageHeader,
    AirdropAside,
    AirdropItemPageOverview,
    AirdropItemPageInfo,
    AirdropItemPageSocial
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          title: 'AirDrop',
          icon: 'eye',
          name: 'AirdropItemPageOverview'
        },
        {
          id: 2,
          title: 'How to participate',
          icon: 'info',
          name: 'AirdropItemPageInfo'
        },
        {
          id: 3,
          title: 'Social',
          icon: 'website',
          name: 'AirdropItemPageSocial'
        }
      ],
      currentTab: 'AirdropItemPageOverview',
      airdropItem: null
    }
  },
  computed: {
    ...mapGetters(['getUserDB'])
  },
  mounted() {
    this.getAirdrop()
  },
  methods: {
    ...mapActions(['setIsNotFound']),
    async getAirdrop() {
      const airdrop = this.$contentful.airdrops.find(({ id }) => id === this.$route.params.id)
      if (!airdrop) {
        await this.setIsNotFound(true)
        return
      }
      const { data } = await api.likes.getLikes(this.$route.params.id, 'airdrop')
      airdrop.likesAmount = data?.[0]?.likesAmount || 0
      airdrop.isLiked = data?.[0]?.isLiked || false
      this.airdropItem = airdrop
    },
    changeActiveTab(componentName) {
      this.currentTab = componentName
    },
    handleLikesValue(value) {
      this.airdropItem.isLiked = value.isLiked
      this.airdropItem.likesAmount = value.likesAmount
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.airdrop-tabs {
  margin-bottom: 19px;
  width: fit-content;

  @include breakpoint-reverse(small) {
    margin-bottom: 30px;
    width: 100%;
  }
}

.airdrop-item {
  @include container;

  @include breakpoint-reverse(medium) {
    padding: 25px 0;
  }

  @include breakpoint-reverse(small) {
    padding: 13px 0;
  }
}

.airdrop-item-content {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 300px;

  @include breakpoint-reverse(medium) {
    grid-template-columns: 1fr;
  }

  @include breakpoint-reverse(small) {
    grid-template-columns: 1fr;
  }
}

.airdrop-item-main {
  overflow-x: hidden;
}

.airdrop-item-aside {
  display: block;

  @include breakpoint-reverse(medium) {
    display: none;
  }
}

.join-button {
  display: none;

  @include breakpoint-reverse(medium) {
    display: block;
    width: 100%;
  }
}
</style>
